import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { BsWhatsapp } from "react-icons/bs";

import '../Estilos/NavHeader.css';

function NavHeader(props) {

    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <Navbar bg="danger" expand="lg" sticky='top' variant="danger" expanded={expanded}>
                <Container>
                    <NavLink to="/">
                        <img
                            src={'./Imagenes/Logo_coleman.webp'}
                            width="150"
                            height="50"
                            className="d-inline-block align-top"
                            alt="Coleman logo"
                            onClick={() => setExpanded(false)}
                        />
                    </NavLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end my-3">
                        <Nav className="nav-gap-10">
                            <NavLink className='nav-bar-item' to="/" onClick={() => setExpanded(false)}>Inicio</NavLink>
                            <NavLink className='nav-bar-item' to="/catalogo" onClick={() => setExpanded(false)}>Catálogo</NavLink>
                            <a className="text-white nav-bar-item" href="https://wa.me/525549716065"><BsWhatsapp className="fs-5 pb-1" /> Contacto</a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default NavHeader;
