import './App.css';
import Catalogo from './Componentes/Catalogo';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavHeader from './Componentes/NavHeader';
import Inicio from './Componentes/Inicio';
import Footer from './Componentes/Footer';

function App() {

  function importAll(r) {
    let images = {};
    //eslint-disable-next-line
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }

  const images = importAll(require.context('../public/Imagenes', false, /\.(png|jpe?g|svg|JPG|jfif|mp4|webp)$/));

  return (
    <div className="">
      <BrowserRouter>
        <header className="sticky-top">
          <NavHeader imagenes={images} />
        </header>
        <Routes>
          <Route path="/" element={<Inicio imagenes={images} />} />
          <Route path="/catalogo" element={<Catalogo />} />
          <Route path="/*" element={<Inicio imagenes={images} />} />
        </Routes>
        <footer>
          <Footer></Footer>
        </footer>
      </BrowserRouter>
    </div>

  );
}

export default App;
