import { Carousel, Image } from "react-bootstrap";

import '../Estilos/Carrusel.css';

function Carrusel(props) {
    return (
        <Carousel controls={false} pause={false}>
            <Carousel.Item>
                <img
                    className="d-block w-100 header"
                    src={'./Imagenes/background-coleman.webp'}
                    alt="Background coleman"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 header"
                    src={'./Imagenes/background-coleman-2.webp'}
                    alt="Background coleman 2"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 header"
                    src={'./Imagenes/background-coleman-3.webp'}
                    alt="Background coleman 3"
                />
            </Carousel.Item>
        </Carousel>
    );
}

export default Carrusel;