import { useState, useEffect, useRef } from "react";
import { db } from '../config/fbconfig';
import { collection, onSnapshot, query, where } from "firebase/firestore";

export const useCollection = (c, _q) => {
    const [documents, setDocuments] = useState(null)
    //const q = useRef(_q)
    const cont = useRef(0)
    //console.log("query real: " + _q)
    //console.log("query entrante: " + q.current)

    useEffect(() => {
        let ref = collection(db, c)
        //console.log("No query")
        if(_q) {
            //console.log("Si hay query")
            ref = query(ref, where(..._q))
        }
        const unsub = onSnapshot(ref, (snapshot) => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({ ...doc.data(), id: doc.id })
            })
            setDocuments(results)
        })
        return () => unsub()
    }, [c, _q])

    //console.log("te regreso: ")
    //console.log(documents)
    cont.current = cont.current + 1
    //console.log("He entrado: " + cont.current + " veces")

    return { documents: documents }
}