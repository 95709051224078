import { Container, Row, Col, Image } from "react-bootstrap"
import Carrusel from "./Carrusel.js"
import { BsTelephone, BsEnvelope } from "react-icons/bs"

import '../Estilos/Inicio.css';

import { analytics, db, logEvent } from "../config/fbconfig";
import { collection, addDoc, query, getDocs, where } from "@firebase/firestore";
import Swal from 'sweetalert2'
import { useState } from "react";
import { NavLink } from 'react-router-dom';

export default function Inicio(props) {

    function MyForm() {
        const usersCollectionReference = collection(db, "mensajesClientes");
        const [inputs, setInputs] = useState({});

        const handleChange = (event) => {
            const name = event.target.name;
            const value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }))
        }

        const handleSubmit = async (event) => {
            event.preventDefault();
            try {
                await addDoc(usersCollectionReference, {
                    nombre: inputs.username,
                    email: inputs.mail,
                    phone: inputs.telefono,
                    mensaje: inputs.message
                }).then(
                    Swal.fire({
                        icon: "success",
                        title: "Perfecto!",
                        text: "Su mensaje se ha enviado",
                        confirmButtonText: "Continuar",
                    })
                ).then(logEvent(analytics, "mensaje_enviado"))
            } catch (err) {
                console.log(err)
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un problema al mandar el mensaje",
                    confirmButtonText: "Continuar",
                }).then(logEvent(analytics, "mensaje_fallido"))
            }
        }


        return (
            <form onSubmit={handleSubmit} className='form-contact'>
                <div className="form-group-sm mb-2">
                    <label>Nombre:</label>
                    <input className='form-control'
                        type="text"
                        name="username"
                        value={inputs.username || ""}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group-sm mb-2">
                    <label>Correo:</label>
                    <input className='form-control'
                        type="email"
                        name="mail"
                        value={inputs.mail || ""}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group-sm mb-2">
                    <label>Teléfono:</label>
                    <input className='form-control'
                        type="number"
                        name="telefono"
                        value={inputs.telefono || ""}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group-sm mb-2">
                    <label>Mensaje:</label>
                    <textarea className='form-control'
                        type="text"
                        name="message"
                        value={inputs.message || ""}
                        onChange={handleChange}
                    />
                </div>
                <input type="submit" className='btn btn-secondary my-2' />
            </form>
        )
    }

    return (
        <div>
            <Carrusel imagenes={props.imagenes}></Carrusel>
            <Container>
                <h1 className="mt-5">COLEMAN MÉXICO</h1>
                <Row className="my-5 justify-content-around">
                    <Col className="align-items-center" xl={4} lg={4} md={5} sm={12} xs={12}>
                        <h3>¿Por qué elegirnos?</h3>
                        <p className="">Brindamos atención profesional, continua e inmediata a nuestros clientes ofreciendo el mejor servicio
                            en todo momento lo cual nos permite innovar, posicionar y mantener a nuestra empresa en un mercado de
                            alta calidad con la gama más completa de productos dedicados al Campismo, los clientes siempre tienen
                            a su disposición los productos Coleman en el momento exacto, y en las condiciones que los necesitan.</p>
                    </Col>
                    <Col xl={8} lg={8} md={7} sm={12} xs={12}>
                        <iframe src="https://youtube.com/embed/xuJZEryQvks" title="Coleman" style={{ "width": "100%", "height": "350px" }}></iframe>
                    </Col>
                </Row>
                <Row className="my-5">
                    <h3>Conoce nuestros productos:</h3>
                </Row>
                <Row className="my-5 justify-content-center" xl={10} lg={9} md={5} sm={3} xs={3}>
                    
                    <NavLink className="cnt my-2" to="/catalogo" state={{ from: "Casas de campaña" }}>
                        <Image src={'./Imagenes/Casas.webp'} fluid alt="" className="image-promo" />
                        <p className="image-text-in">Casas de campaña</p>
                    </NavLink>
                    <NavLink className="cnt my-2" to="/catalogo" state={{ from: "Hieleras" }}>
                        <Image src={'./Imagenes/Hieleras3-fondo.jpg'} fluid alt="" className="image-promo" />
                        <p className="image-text-in">Hieleras</p>
                    </NavLink>
                    <NavLink className="cnt my-2" to="/catalogo" state={{ from: "Bolsas de dormir" }}>
                        <Image src={'./Imagenes/Bolsas.png'} fluid alt="" className="image-promo" />
                        <p className="image-text-in">Bolsas de dormir</p>
                    </NavLink>
                    <NavLink className="cnt my-2" to="/catalogo" state={{ from: "Termos" }}>
                        <Image src={'./Imagenes/Termos1-fondo.jpg'} fluid alt="" className="image-promo" />
                        <p className="image-text-in">Termos</p>
                    </NavLink>
                </Row>
                <Row className="my-5" xl={2} lg={2} md={2} sm={1} xs={1}>
                    <Col className="my-2">
                        <h3>Contáctanos: </h3>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7529.343363808627!2d-99.059572!3d19.340048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6cdb096bbc9af42!2sColeman%20Camping%20M%C3%A9xico!5e0!3m2!1ses-419!2smx!4v1675989943913!5m2!1ses-419!2smx" style={{ "width": "100%", "height": "auto" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="ubicacion"></iframe>
                        <p className="fs-6">AV. 5 de Mayo Mz 12 Lt 1. Col. Francisco Villa Alcaldía Iztapalapa C.P. 09720 CDMX</p>
                        <ul className="list-unstyled contact-list">
                            <li><BsEnvelope /> ventas@colemanmx.com.mx</li>
                            <li><BsTelephone /> 55 5037 6191 / 55 4971 6065</li>
                            {/* <li><BsFacebook /> <BsInstagram /></li> */}
                        </ul>
                    </Col>
                    <Col className="my-2">
                        <h3>Escríbenos un mensaje: </h3>
                        <MyForm></MyForm>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}