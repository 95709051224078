// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import 'firebase/firestore'
import 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCb2fSl5AMdkbKW9PyDdm37vRbguGvaIlw",
  authDomain: "coleman-mx.firebaseapp.com",
  projectId: "coleman-mx",
  storageBucket: "coleman-mx.appspot.com",
  messagingSenderId: "739545289824",
  appId: "1:739545289824:web:ddb1c7876f66b3daf49f05",
  measurementId: "G-JYBN7HCLZV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();
const storage = getStorage();

export { db, app, analytics, storage, logEvent };