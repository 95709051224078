import { Container, Row, Col } from "react-bootstrap"
import { BsWhatsapp, BsFacebook, BsMessenger, BsInstagram } from "react-icons/bs";
//import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

export default function Footer() {

    return (
        <div className="bg-danger text-white pb-4">
            <Container>
                <Row xs={1} sm={1} md={1} lg={3} xl={3} className="pt-3 text-start justify-content-between">
                    <Col className="my-2">
                        <p>© 2021 Copyright: COLEMANMX DISTRIBUIDOR A TODO MÉXICO Y LATINOAMERICA</p>
                    </Col>
                    <Col className="fs-5 my-2">
                        <p className="fw-bold">Productos</p>
                        <NavLink to="/catalogo" className="text-white text-decoration-none" state={{ from: "Casas de campaña" }}>Casas de campaña</NavLink><br />
                        <NavLink to="/catalogo" className="text-white text-decoration-none" state={{ from: "Hieleras" }}>Hieleras</NavLink><br />
                        <NavLink to="/catalogo" className="text-white text-decoration-none" state={{ from: "Bolsas de dormir" }}>Bolsas de dormir</NavLink><br />
                        <NavLink to="/catalogo" className="text-white text-decoration-none" state={{ from: "Termos" }}>Termos</NavLink><br />
                        {/*<NavLink to="/catalogo" className="text-white text-decoration-none" state={{ from: "Termos" }}>Termos</NavLink><br />
                        <NavLink to="/catalogo" className="text-white text-decoration-none" state={{ from: "Toldos" }}>Toldos</NavLink><br />*/}
                    </Col>
                    <Col className="">
                        <p className="fs-5 fw-bold my-2">Redes sociales:</p>
                        <div className="d-flex flex-row justify-content-start">
                            <a className="text-white" href="https://www.facebook.com/ColemanCampingMexico/"><BsFacebook className="fs-5 mx-2" /></a>
                            <a className="text-white" href="https://m.me/ColemanCampingMexico/"><BsMessenger className="fs-5 mx-2" /></a>
                            <a className="text-white" href="https://www.instagram.com/colemancampingmexico"><BsInstagram className="fs-5 mx-2" /></a>
                            <a className="text-white" href="https://wa.me/525549716065"><BsWhatsapp className="fs-5 mx-2" /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}