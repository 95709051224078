import { Container, Row, Col, Image, Button, Carousel, OverlayTrigger, Tooltip, Modal, DropdownButton, Dropdown } from "react-bootstrap"
import { useState, useEffect, useRef } from "react"
import Paginacion from "./Paginacion"
//import articul from '../Datos/articulos.json'
import { BsListUl, BsFillGridFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
//import jsonMultilineStrings from "json-multiline-strings"


//import { db } from '../config/fbconfig';
//import { collection, onSnapshot, query, where } from "firebase/firestore";


//import { collection, getDocs } from "firebase/firestore";
import { useCollection } from "../hooks/useCollection";

import '../Estilos/Catalogo.css'

let PageSize = 8;

export default function Catalogo(props) {


    //console.log(productos)

    let fil_temp = "Todos"
    let q_temp = null
    const location = useLocation()
    if (location.state !== null) {
        const { from } = location.state
        fil_temp = from
        q_temp = ["tipo", "==", fil_temp]
    }

    const handleClick = async (id) => {
        console.log(id)
    }

    const [filtro, setFiltro] = useState(fil_temp)
    const [qu, setQu] = useState(q_temp)
    const { documents: productos } = useCollection('productos', qu)
    const totalCount = useRef(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [modalShow, setModalShow] = useState(false);
    const [producto, setProducto] = useState({imagenes: ["", ""], caracteristicas: ["", ""], descripcion: ""});
    const articulosList = useRef([])


    articulosList.current = productos
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (productos) {
        totalCount.current = productos.length
        if (filtro !== "Todos") {
            articulosList.current.sort(function (a, b) {
                let x = a.nombre.toLowerCase();
                let y = b.nombre.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            });
        }
        articulosList.current = productos.slice(firstPageIndex, lastPageIndex)
    }

    useEffect(() => {
        //console.log("primero: " + firstPageIndex + " Ultimo: " + lastPageIndex)
        if (filtro === "Todos") {
            setQu(null)
        } else {
            setQu(["tipo", "==", filtro])
        }

    }, [currentPage, filtro]);


    const [info, setInfo] = useState("hidden")
    const [ancho, setAncho] = useState([4, 3, 1])
    const [display, setDisplay] = useState("flex-column")
    const [borde, setBorde] = useState("")
    const [botones, setBotones] = useState("hidden")
    const [botonValor, setBotonValor] = useState("")

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        if (screenSize.dynamicWidth <= 576) {
            setBotones("hidden")
            setDisplay("d-flex flex-column align-items-start justify-content-start border-line");
            setInfo("show");
            setAncho([1, 1, 1, 1, 1]);
            setBorde("");
        } else {
            if (botonValor === "cuadricula") {
                setDisplay("d-flex flex-column align-items-start justify-content-start");
                setInfo("hidden");
                setAncho([4, 3, 3, 1, 1]);
                setBorde("")
            } else if (botonValor === "lista") {
                setDisplay("d-flex flex-row align-items-center justify-content-start border-line gap-5");
                setInfo("show");
                setAncho([1, 1, 1, 1, 1]);
                setBorde("list-display");
            }
            setBotones("show")
        }

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize, botonValor])

    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        //document.getElementById("root").scrollTop = 0;
    }

    return (
        <div>
            <Container>
                <Row className={`my-5 border-line align-items-center justify-content-between`} xs={1}>
                    <h2>Lista de productos: </h2>
                    <DropdownButton variant="danger" title={`Ordenar por: ${filtro}`} className="my-2 text-start">
                        <Dropdown.Item eventKey="1" onClick={() => { setFiltro("Todos"); setCurrentPage(1); }}>Todos</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="2" onClick={() => { setFiltro("Casas de campaña"); setCurrentPage(1); }}>Casas de campaña</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={() => { setFiltro("Hieleras"); setCurrentPage(1); }}>Hieleras</Dropdown.Item>
                        <Dropdown.Item eventKey="4" onClick={() => { setFiltro("Bolsas de dormir"); setCurrentPage(1); }}>Bolsas de dormir</Dropdown.Item>
                        <Dropdown.Item eventKey="5" onClick={() => { setFiltro("Termos"); setCurrentPage(1); }}>Termos</Dropdown.Item>
                        {/*<Dropdown.Item eventKey="4" onClick={() => { setFiltro("Termos"); setCurrentPage(1); }}>Termos</Dropdown.Item>
                        <Dropdown.Item eventKey="5" onClick={() => { setFiltro("Toldos"); setCurrentPage(1); }}>Toldos</Dropdown.Item>*/}
                    </DropdownButton>
                </Row>
                <div className={`${botones} my-2`}>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Desplegar como cuadrícula</Tooltip>}
                    >
                        <Button className={`mx-1 ${botones}`} variant="secondary" onClick={() => {
                            setBotonValor("cuadricula")
                        }
                        }><BsFillGridFill /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">Desplegar como lista</Tooltip>}
                    >
                        <Button className={`mx-1 ${botones}`} variant="secondary" onClick={() => {
                            setBotonValor("lista")
                        }
                        }><BsListUl /></Button>
                    </OverlayTrigger>
                </div>
                <Row xl={ancho[0]} lg={ancho[1]} md={ancho[2]} sm={ancho[3]} xs={ancho[4]} className={borde}>
                    {articulosList.current && articulosList.current.map(articulo => {
                        return (
                            <li className="list-unstyled" key={articulo.id} onClick={() => handleClick(articulo.id)}>
                                <Col className={`py-3 ${display}`}>
                                    <div className="imagen-div">
                                        <Image className="product-img" src={`${articulo.imagen}`} alt="Producto" fluid onClick={() => {
                                            setProducto(articulo);
                                            setModalShow(true);
                                        }} />
                                    </div>
                                    <div className="product-info">
                                        <h3>{articulo.nombre}</h3>
                                        <h5 className={info}>Características: </h5>
                                        <p className={info}>{articulo.desc1}</p>
                                        <p className={info}>{articulo.desc2}</p>
                                        <Button variant="danger" onClick={() => {
                                            setProducto(articulo);
                                            setModalShow(true);
                                        }}>Ver más</Button>
                                    </div>
                                </Col>
                            </li>
                        );
                    })}
                    <Modal show={modalShow} onHide={() => setModalShow(false)} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton className="bg-danger text-white">
                            <Modal.Title id="contained-modal-title-vcenter">
                                {producto.nombre}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row xl={3} lg={2} md={1} sm={1} xs={1}>
                                <Col className="align-self-center my-1">
                                    <Carousel pause={"hover"} fade indicators={false} className="">
                                        {producto && producto.imagenes.map((img) => (
                                            <Carousel.Item>
                                                <Image src={img} alt="" fluid></Image>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Col>
                                <Col className="overflow-auto mod-carac my-1">
                                    <h4>Características</h4>
                                    {producto && producto.caracteristicas.map((car) => (
                                        <p>{car}</p>
                                    ))}
                                </Col>
                                <Col lg={12} className="overflow-auto mod-desc my-1">
                                    <h4>Descripción</h4>
                                    <p>{producto.descripcion}</p>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="bg-danger">
                            <a href="https://wa.me/525549716065"><Button variant="success"><BsWhatsapp /> Contacto</Button></a>
                            <Button variant="secondary" onClick={() => setModalShow(false)}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>
                </Row>
                <Row className="" onClick={() => { topFunction() }}>
                    <Paginacion
                        className="pagination-bar justify-content-center my-2"
                        currentPage={currentPage}
                        totalCount={totalCount.current}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </Row>
            </Container>
        </div >
    )
}